
import { Options, Vue } from "vue-class-component";
import SectionTitle from "@/shared/components/SectionTitle.vue";
import QuestionnaireSectionForm from "@/components/forms/QuestionnaireSectionForm.vue";
import { Question, QuestionnaireSection } from "@/shared/types/Questionnaire";
import { toCamelCase } from "@/utils/helpers";
import LeftStepBar from "@/shared/components/LeftStepBar.vue";

@Options({
  components: {
    SectionTitle,
    QuestionnaireSectionForm,
    LeftStepBar,
  },
  beforeMount() {
    this.$store.commit("onboarding/checkProfile");
  },
  created() {
    this.$store.dispatch("questionnaire/setQuestionnaire", this.$i18n.locale);
  },
  computed: {
    questionnaire(): Questionnaire {
      return this.$store.getters["questionnaire/getQuestionnaire"];
    },
    currentSection() {
      if (this.questionnaire) {
        return this.questionnaire.sections.find(
          (s: QuestionnaireSection) =>
            s.id === this.$store.getters["questionnaire/getCurrentSection"]
        );
      }

      return null;
    },
  },
  methods: {
    generateFormKeys() {
      return this.currentSection.questions.map((q: Question) =>
        toCamelCase(q.slug)
      );
    },
  },
})
export default class Questionnaire extends Vue {}
