
import { Options, Vue } from "vue-class-component";
import FormRadioGroup from "@/shared/components/FormRadioGroup.vue";
import FormMultipleChoiceGroup from "@/shared/components/FormMultipleChoiceGroup.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@/utils/i18n-validators";
import { Answer, Question, SelectedAnswer } from "@/shared/types/Questionnaire";
import { toCamelCase } from "@/utils/helpers";
import { mapGetters } from "vuex";

@Options({
  data() {
    return {
      v$: useVuelidate(),
      formSubmitted: false,
      validationErrors: [],
      form: {},
    };
  },
  props: {
    section: {
      required: true,
    },
    formKeys: {
      required: true,
      type: Array,
    },
  },
  computed: {
    ...mapGetters({
      riskProfile: "questionnaire/getRiskProfile",
      // riskProfiles: "questionnaire/getRiskProfiles",
      investorName: "questionnaire/getInvestorName",
    }),
    validators() {
      return this.section.questions.reduce((map: any, q: Question) => {
        map[toCamelCase(q.slug)] = { required, $lazy: true };
        return map;
      }, {});
    },
    canShowQuestion() {
      return (question: Question) => {
        if (!question.where) {
          return true;
        }

        for (const key in question.where) {
          if (Object.prototype.hasOwnProperty.call(question.where, key)) {
            const value = question.where[key];
            let formValue = this.form[toCamelCase(key)];
            if (typeof formValue === "object") {
              for (let index = 0; index < formValue.length; index++) {
                const answer: SelectedAnswer = formValue[index];

                if (answer.answer != value) {
                  return false;
                }
              }
            } else {
              if (formValue != value) {
                return false;
              }
            }
          }
        }

        return true;
      };
    },
  },
  components: {
    FormRadioGroup,
    FormMultipleChoiceGroup,
  },
  methods: {
    getKey(question: Question) {
      return toCamelCase(question.slug);
    },
    getLabel(question: Question) {
      if (typeof question.order == "string") {
        return `${question.order} ${question.question}`;
      }

      return `${question.order}. ${question.question}`;
    },
    getQuestionArray(question: Question) {
      if (question.sub) {
        return question.questions;
      }

      return [question];
    },
    nextSection() {
      this.formSubmitted = true;
      const validationErrors: any[] = [];
      this.v$.$touch();

      this.formKeys.forEach((key: string, i: number) => {
        if (
          (!this.form[key] && !this.section.questions[i].notRequired) ||
          (this.form[key] && !this.form[key].length)
        ) {
          validationErrors.push({
            key,
            error: this.v$.form[key].$errors[0],
          });
        }
      });

      this.validationErrors = validationErrors;
      console.log(this.formKeys);
      console.log(this.form);
      console.log(validationErrors);
      if (!Object.keys(this.validationErrors).length) {
        const answers: SelectedAnswer[] = [];

        this.formKeys.forEach((keyMain: string, i: number) => {
          let questions = [this.section.questions[i]];
          if (this.section.questions[i].sub) {
            questions = this.section.questions[i].questions;
          }

          questions.forEach((question: Question) => {
            let key = toCamelCase(question.slug);

            if (typeof this.form[key] !== "undefined") {
              if (typeof this.form[key] === "object") {
                this.form[key].forEach((answer: SelectedAnswer) => {
                  answers.push({
                    ...answer,
                    question: `step${this.section.id}_${question.slug}`,
                  });
                });
              } else {
                var points = 0;
                if (question.answers) {
                  let answer = question.answers.find(
                    (answer: Answer) => answer.value === this.form[key]
                  );

                  if (answer && answer.points) {
                    points = answer.points;
                  }
                }
                answers.push({
                  question: `step${this.section.id}_${question.slug}`,
                  answer: this.form[key],
                  points: points,
                  text: "",
                });
              }
            }
          });
        });
        this.$store.dispatch("questionnaire/nextSection", answers);
        this.$store.dispatch("questionnaire/getRiskProfiles", this.riskProfile);
        window.scrollTo(0, 0);
      }
    },
    questionHasError(key: string) {
      const error = this.validationErrors.find((e: any) => e.key === key);
      if (error) {
        return true;
      }
      return false;
    },
    getQuestionError(key: string) {
      const error = this.validationErrors.find((e: any) => e.key === key);
      if (error) {
        return error.error;
      }
      return false;
    },
  },
  watch: {
    formKeys() {
      this.form = this.formKeys.reduce((map: any, key: string) => {
        map[key] = "";
        return map;
      }, {});
    },
  },
  validations() {
    return {
      form: this.validators,
    };
  },
})
export default class QuestionnaireSectionForm extends Vue {}
