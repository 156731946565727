import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "block w-full text-sm mb- text-primary mb-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormMultipleChoiceGroup = _resolveComponent("FormMultipleChoiceGroup")!
  const _component_FormRadioGroup = _resolveComponent("FormRadioGroup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formKeys, (key, i) => {
      return (_openBlock(), _createElementBlock("div", { key: i }, [
        (_ctx.canShowQuestion(_ctx.section.questions[i]))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.section.questions[i].sub)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.section.questions[i].order) + ". " + _toDisplayString(_ctx.section.questions[i].question), 1))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getQuestionArray(_ctx.section.questions[i]), (question, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass({ 'ml-5': _ctx.section.questions[i].sub })
                }, [
                  (_ctx.canShowQuestion(question))
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (question.multiple)
                          ? (_openBlock(), _createBlock(_component_FormMultipleChoiceGroup, {
                              key: 0,
                              class: "mb-7",
                              classes: "flex-col justify-start items-start space-x-0",
                              label: _ctx.getLabel(question),
                              labelClasses: "text-primary mb-1",
                              options: question.answers,
                              optionClasses: "space-x-5 space-y-1.5 items-start",
                              model: _ctx.form[_ctx.getKey(question)],
                              "onUpdate:model": ($event: any) => (_ctx.form[_ctx.getKey(question)] = $event),
                              name: `${_ctx.getKey(question)}`,
                              hasError: _ctx.questionHasError(_ctx.getKey(question)),
                              error: _ctx.getQuestionError(_ctx.getKey(question))
                            }, null, 8, ["label", "options", "model", "onUpdate:model", "name", "hasError", "error"]))
                          : (_openBlock(), _createBlock(_component_FormRadioGroup, {
                              key: 1,
                              class: "mb-7",
                              classes: "flex-col justify-start items-start space-x-0",
                              label: _ctx.getLabel(question),
                              labelClasses: "text-primary mb-1",
                              options: question.answers,
                              optionClasses: "space-x-5 space-y-1.5 items-start",
                              model: _ctx.form[_ctx.getKey(question)],
                              "onUpdate:model": ($event: any) => (_ctx.form[_ctx.getKey(question)] = $event),
                              name: `${_ctx.getKey(question)}`,
                              hasError: _ctx.questionHasError(_ctx.getKey(question)),
                              error: _ctx.getQuestionError(_ctx.getKey(question))
                            }, null, 8, ["label", "options", "model", "onUpdate:model", "name", "hasError", "error"]))
                      ], 64))
                    : _createCommentVNode("", true)
                ], 2))
              }), 128))
            ], 64))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.nextSection && _ctx.nextSection(...args))),
      class: "mb-24 w-full btn secondary flex-1 font-sans"
    }, _toDisplayString(_ctx.$t("continue")), 1)
  ], 64))
}